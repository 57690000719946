import * as React from 'react';
import styled from 'styled-components';

interface IPlayButton {
  className?: string;
  label?: string;
  onClick: () => void;
}

export const PlayButton: React.FC<IPlayButton> = ({
  className,
  label,
  onClick,
}) => {
  return (
    <PlayButtonInside className={className} onClick={onClick}>
      <svg
        width="96"
        height="96"
        viewBox="0 0 96 96"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="48" cy="48" r="45.5" strokeWidth="5" />
        <path d="M69.5 45.4019C71.5 46.5566 71.5 49.4434 69.5 50.5981L39.5 67.9186C37.5 69.0733 35 67.6299 35 65.3205L35 30.6795C35 28.3701 37.5 26.9267 39.5 28.0814L69.5 45.4019Z" />
      </svg>
      {label ? <span className="play-button-label">{label}</span> : null}
    </PlayButtonInside>
  );
};

const PlayButtonInside = styled.button`
  display: flex;
  align-items: center;
  flex-flow: column;
  background: none;
  border: none;
  outline: none;
  position: relative;
  align-self: center;
  top: 0;
  bottom: 0;
  height: 120px;

  .play-button-label {
    display: block;
    width: 100%;
    margin-top: 20px;
    font-size: 16px;
    color: white;
    text-align: center;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }

  svg {
    width: 100px;
    height: 100px;

    circle {
      stroke: ${(props) => props.theme.colors.primary};
    }

    path {
      fill: ${(props) => props.theme.colors.primary};
    }
  }

  &.large-btn {
    svg {
      width: 149px;
      height: 149px;
    }
  }
`;
