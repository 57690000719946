import * as React from 'react';

interface IClickOutside {
  ref: any;
  onClickOutside: () => void;
}

export const useClickoutside = (args: IClickOutside) => {
  const clickOutside = (e: any) => {
    if (args?.ref?.current?.contains(e.target)) {
      return;
    } else {
      if (typeof args.onClickOutside === 'function') {
        args.onClickOutside();
      }
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', clickOutside);
    return () => {
      document.removeEventListener('mousedown', clickOutside);
    };
  }, []);
};
